import { observe } from "@/ObservedComponent";
import ContextualFooter from "@/components/ContextualFooter";
import Loading from "@/components/Loading/Index";
import SessionRecorderFooter from "@/components/SessionRecorderFooter/Index";
import { db } from "@/db/db";
import { accountEvent, feed, item, item as itemTable } from "@/db/schema";
import Locator from "@/locator";
import { AppContext } from "@/models/AppStateProvider";
import { FeedContext } from "@/models/FeedContextProvider";
import { CurrentFeedContext } from "@/models/StateProviders/currentFeedProvider";
import { MyAccountContext } from "@/models/StateProviders/myAccountProvider";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { actions, TelemetryContext } from "@/models/TelemetryProvider";
import { UxContext } from "@/models/UxStateProvider";
import { downloadFeedItemContent } from "@/models/actions/initialFeedLoad";
import { VolumeOffOutlined } from "@mui/icons-material";
import {
  Alert,
  Box,
  Fade,
  Snackbar,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  Fragment,
  startTransition,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "regenerator-runtime/runtime";
import { VList } from "virtua";
import FeedItem from "./FeedItem";
import FeedItemHeader from "./FeedItemHeader";
import { drawerOpenStyles } from "./SBAppBar";
import cuid from "cuid";
import { eq } from "drizzle-orm";

function FeedC() {
  const {
    status: feedStatus,
    itemTranscriptState,
    itemMessageDetailsState,
    vListRef,
    items,
    feedMap,
  } = useContext(FeedContext);
  const navigate = useNavigate();
  const { isSmUp, leftNavOpen, rightNavOpen, userReadOnlyMode } =
    useContext(UxContext);
  const [fetching, setFetching] = useState<boolean>(false);
  const [activeFeedItemId, setActiveFeedItemId] = useState<string | null>(null);
  const [scrolling, setScrolling] = useState(false);
  const [range, setRange] = useState([-1, -1]);
  const { client } = useContext(AppContext);
  const { workspaceId, feedId } = useParams();

  const { currentFeed, haveWritePermission } = useContext(CurrentFeedContext);
  const { currentWorkspaceId } = useContext(WorkspaceContext);
  const { myAccountId } = useContext(MyAccountContext);
  const { trackAction, finishAction } = useContext(TelemetryContext);
  const { hash } = useLocation();
  const hashValue = hash?.split("#")?.[1] ?? null;
  const wls = window?.location?.search;
  const feedItemId = new URLSearchParams(wls).get("feedItemId") ?? null;
  const isSilentChannel = currentFeed?.isSilent;

  let currentFeedItems = feedMap;
  const feedItemsWithIds = items?.filter((item) => item.feedId === feedId);
  const totalItems = useRef<number>(0);

  const stopScroll = useRef<boolean>(false);
  const delayAddUserWindow = useRef<boolean>(true);
  const [activeStickyItem, setActiveStickyItem] = useState(null);

  const initialLoad = useRef(true);
  const initialContentLoad = useRef(true);
  const shouldStickToBottom = useRef(false);
  const loadingContent = useRef(false);

  useEffect(() => {
    setTimeout(() => {
      delayAddUserWindow.current = false;
    }, 2000);
  }, []);

  const dateGroups = useMemo(
    () =>
      currentFeedItems?.reduce((acc, item, index) => {
        if (!acc[item?.shortDateStr]) {
          acc[item?.shortDateStr] = {
            firstItemIndex: index,
            lastItemIndex: index,
          };
        } else {
          acc[item?.shortDateStr].lastItemIndex = index;
        }
        return acc;
      }, {}),
    [currentFeedItems],
  );

  const unreadItems = useMemo(
    () =>
      items
        ?.filter((i) => i.unread)
        .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1)),
    [items],
  );

  const canRecordOrUpload = haveWritePermission;
  const theme = useTheme();
  const unreadCount = unreadItems?.length;
  const firstUnseen =
    myAccountId && unreadCount > 0 ? unreadItems[0]?.id : null;
  const unreadIndex = useMemo(
    () =>
      currentFeedItems?.find(
        (feedItem) => unreadItems?.[0]?.id === feedItem?.id,
      )?.feedItemIndex,
    [currentFeedItems, unreadItems],
  );

  const keepMountedItems = useMemo(() => {
    return currentFeedItems
      ?.map((item, index) => {
        const transcriptStateActive = itemTranscriptState?.includes(item?.id);
        const messageDetailState =
          itemMessageDetailsState[item?.id] !== undefined;
        const firstUnseenState = firstUnseen === item?.id;
        if (transcriptStateActive || messageDetailState || firstUnseenState) {
          return index;
        }
      })
      .filter((i) => i !== undefined);
  }, [
    currentFeedItems,
    itemTranscriptState,
    itemMessageDetailsState,
    firstUnseen,
  ]);

  const lastItemSpacing = useMemo(() => {
    const spacing = isSilentChannel ? 245 : 220;
    if (isSmUp) {
      return `${spacing}px`;
    }
    return `${spacing + 80}px`;
  }, [isSmUp, isSilentChannel]);

  // Formally used for paginated data infinite loading
  const rangeChangeLoadMore = async (
    vListIndexStart: number,
    vListIndexEnd: number,
  ) => {
    if (items.length === 0) return;
    if (loadingContent.current) return;

    let allItemsNotLoadedUpWithin20 = items?.filter((item, index) => {
      return (
        index > vListIndexStart - 20 &&
        index < vListIndexEnd + 20 &&
        !item.loadedContent
      );
    });

    if (initialContentLoad.current) {
      allItemsNotLoadedUpWithin20 = Array.from(
        new Set(
          allItemsNotLoadedUpWithin20.concat(
            items?.slice(-20).filter((item) => !item.loadedContent),
          ),
        ),
      );
    }
    console.log("checking if we need to load more feed item content", {
      allItemsNotLoadedUpWithin20,
      vListIndexEnd,
      vListIndexStart,
      initialContentLoad: initialContentLoad.current,
    });

    const spanName = initialContentLoad.current
      ? actions.feedInitial(currentFeed)
      : actions.feedScroll(currentFeed);
    initialContentLoad.current = false;

    if (allItemsNotLoadedUpWithin20.length === 0) {
      return;
    }
    loadingContent.current = true;

    const itemContentIds = allItemsNotLoadedUpWithin20?.map((i) => i.id);

    const span = trackAction(spanName);
    span.setAttribute("feedId", feedId);
    span.setAttribute("feed.itemsLoaded", itemContentIds.length.toString());
    console.log("About to download feed item content", {
      allItemsNotLoadedUpWithin20,
    });
    downloadFeedItemContent(client, currentWorkspaceId, itemContentIds)
      .then(() => {
        console.log("Downloaded feed item content", {
          allItemsNotLoadedUpWithin20,
        });
        finishAction(spanName);
        loadingContent.current = false;
        // setTimeout(async () => {
        //   const itemRecord = await db.query.item
        //     .findFirst({ where: eq(feed.id, feedId) })
        //     .execute();
        //   if (itemRecord) {
        //     await db.update(itemTable).set({ id: itemRecord.id });
        //   }
        //   // const eventRecord = await db.query.accountEvent
        //   //   .findFirst({})
        //   //   .execute();
        //   // if (eventRecord) {
        //   //   await db.update(accountEvent).set({ id: eventRecord.id });
        //   // }
        // }, 250);
      })
      .catch((e) => {
        finishAction(spanName, { error: e.message });
        loadingContent.current = false;
        console.error("Error downloading feed item content", e);
      });
  };

  const scrollToIndexAndHighlight = (id: string, highlight: boolean) => {
    const indexName = items?.findIndex((item) => item.id === id);
    if (indexName > -1) {
      vListRef.current?.scrollToIndex(indexName, { align: "start" });
      stopScroll.current = false;
      rangeChangeLoadMore(indexName, indexName);
      if (highlight) {
        setActiveFeedItemId((prev) => (prev !== id ? id : prev));
        setTimeout(() => {
          stopScroll.current = true;
          const url = `/workspaces/${workspaceId}/feeds/${feedId}`;
          navigate(url);
          setActiveFeedItemId(() => null);
          setTimeout(() => {
            stopScroll.current = false;
          }, 1500);
        }, 5000);
      }
    }
  };

  // if the url has a #id or ?feedItemId then scroll to that item and highlight it
  // there should only ever be two scroll actions: on feed change and if the url has a #id or ?feedItemId
  useEffect(() => {
    if (!vListRef.current) {
      return;
    }
    if (
      totalItems.current === feedItemsWithIds?.length &&
      feedItemsWithIds?.length > 0 &&
      totalItems.current &&
      stopScroll.current
    ) {
      return;
    }

    const misMatch =
      totalItems.current !== feedItemsWithIds?.length &&
      feedItemsWithIds?.length > 0 &&
      totalItems.current;

    if (
      misMatch ||
      (feedItemsWithIds?.length > 0 && !hashValue && !feedItemId)
    ) {
      const lastItemId = items[items?.length - 1]?.id;
      scrollToIndexAndHighlight(lastItemId, false);
      initialLoad.current = false;
    } else if (feedItemsWithIds?.length > 0 && (hashValue || feedItemId)) {
      scrollToIndexAndHighlight(hashValue ?? feedItemId, true);
    }
    totalItems.current = feedItemsWithIds?.length;
  }, [
    currentFeedItems?.length,
    feedItemsWithIds?.length,
    feedId,
    hashValue,
    feedItemId,
    vListRef.current,
  ]);

  const handleRangeChange = async (startIndex: number, endIndex: number) => {
    const dateGroup = dateGroups[currentFeedItems?.[startIndex]?.shortDateStr];
    if (
      !initialLoad.current &&
      startIndex >= dateGroup?.firstItemIndex &&
      startIndex <= dateGroup?.lastItemIndex
    ) {
      if (
        (unreadCount > 0 &&
          startIndex >= unreadIndex &&
          unreadIndex <= endIndex) ||
        (startIndex < unreadIndex && unreadIndex > endIndex)
      ) {
        setActiveStickyItem({
          ...currentFeedItems[unreadIndex],
          index: unreadIndex,
          isUnreadAbove: unreadIndex < startIndex,
          showArrow: unreadIndex !== startIndex,
        });
      } else {
        setActiveStickyItem(null);
      }
    }
    await rangeChangeLoadMore(startIndex, endIndex);
  };

  useEffect(() => {
    initialLoad.current = true;
    initialContentLoad.current = true;
    currentFeedItems = [];
  }, [feedId]);

  const handleVListScroll = (offset?: number) => {
    setScrolling(true);
    // if were at the bottom of the list, then stick to the bottom, otherwise maintain scroll position
    shouldStickToBottom.current =
      offset - vListRef.current.scrollSize + vListRef.current.viewportSize >= 0;
  };

  const _drawerOpenStyles = {
    ...drawerOpenStyles({
      isSmUp,
      leftNavOpen,
      rightNavOpen,
      theme,
    }),
  };

  if (!feedId || !currentFeedItems) return null;

  return (
    <>
      {feedStatus && feedStatus?.message ? (
        <Fade in={feedStatus?.message !== null} timeout={500}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            sx={{
              width: "100%",
              padding: 1,
              top: { xs: 100 },
              left: { xs: 0 },
              transform: { xs: "none" },
              zIndex: 1199,
              ..._drawerOpenStyles,
            }}
            open={true}
          >
            <Alert
              severity={feedStatus?.severity}
              sx={{
                width: "100%",
                color: theme.palette.primary.main,
                maxWidth: 425,
              }}
              icon={false}
              elevation={6}
              variant="filled"
            >
              {feedStatus?.message}
            </Alert>
          </Snackbar>
        </Fade>
      ) : null}
      {fetching ? (
        <Box
          sx={{
            p: 2,
            height: 80,
            display: "flex",
            alignItems: "center",
            position: "fixed",
            justifyContent: "center",
            left: 0,
            top: "120px",
            width: "100%",
            zIndex: 200,
            ..._drawerOpenStyles,
          }}
        >
          <Loading size="small" />
        </Box>
      ) : null}
      <Stack
        sx={{
          position: "fixed",
          top: 108,
          left: 0,
          width: "100%",
          zIndex: 1100,
          ..._drawerOpenStyles,
        }}
      >
        {activeStickyItem ? (
          <FeedItemHeader
            isStuck={true}
            dateId={activeStickyItem?.shortDateStr}
            onClick={() =>
              scrollToIndexAndHighlight(activeStickyItem?.id, false)
            }
            isUnreadHeader={firstUnseen && firstUnseen === activeStickyItem?.id}
            unreadCount={unreadCount}
            unreadAbove={activeStickyItem?.isUnreadAbove}
            showArrow={activeStickyItem?.showArrow}
          />
        ) : null}
      </Stack>
      {currentFeedItems?.length > 0 ? (
        <VList
          className="scrollable-content"
          aria-label={Locator.feed.items.list}
          data-testid={Locator.feed.testId(feedId)}
          style={{
            flex: 1,
            height: "calc(100svh - 92px)",
            paddingRight: "6px",
            paddingLeft: "16px",
          }}
          reverse
          shift={false}
          // setting to "0" removes the bottom jump issue
          overscan={0}
          ref={vListRef}
          id="vListRef"
          keepMounted={keepMountedItems}
          onRangeChange={(startIndex, endIndex) => {
            startTransition(() => {
              setRange([startIndex, endIndex]);
            });
            requestAnimationFrame(() =>
              handleRangeChange(startIndex, endIndex),
            );
          }}
          onScroll={handleVListScroll}
          onScrollEnd={() => {
            startTransition(() => {
              setScrolling(false);
            });
          }}
          itemSize={172}
        >
          {currentFeedItems.map((feedItem, index) => (
            <Fragment key={`feed-item-${feedItem?.id}`}>
              {feedItem.isFirstDateGroupItem ||
              (firstUnseen && firstUnseen === feedItem?.id) ? (
                <FeedItemHeader
                  isStuck={false}
                  dateId={feedItem.shortDateStr}
                  onClick={() => scrollToIndexAndHighlight(feedItem.id, false)}
                  dateLabel={feedItem.dateGroupLabel}
                  isUnreadHeader={firstUnseen && firstUnseen === feedItem?.id}
                  unreadCount={unreadCount}
                />
              ) : (
                <Box sx={{ height: 34 }}></Box>
              )}
              <Box
                id={`item-${index}`}
                aria-label={Locator.feed.items.item}
                data-testid={feedItem.id}
                sx={{
                  maxWidth: { xs: 1300 },
                  margin: "0 auto",
                  display: "flex",
                  flexDirection: "column",
                  pb:
                    index === currentFeedItems?.length - 1
                      ? lastItemSpacing
                      : 0,
                  justifyContent: feedItem.mine ? "flex-end" : "flex-start",
                }}
              >
                <FeedItem
                  feed={currentFeed}
                  item={feedItem}
                  vListIndex={index}
                  active={
                    feedItem.id === feedItemId ||
                    feedItem.id === activeFeedItemId
                  }
                  highlightPodcast={feedItem.id === activeFeedItemId}
                />
              </Box>
            </Fragment>
          ))}
        </VList>
      ) : (
        <Box
          aria-label={Locator.feed.items.list}
          data-testid={Locator.feed.testId(feedId)}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            height:
              currentFeedItems.length === 0 ? "calc(100svh - 165px)" : "auto",
            color: theme.palette.secondary.light,
          }}
        >
          <Typography
            variant="h6"
            component="h4"
            color={theme.palette.primary.main}
            fontWeight={700}
          >
            There are no messages yet
          </Typography>
        </Box>
      )}
      {isSilentChannel && canRecordOrUpload ? (
        <ContextualFooter
          containerSx={{
            bottom: canRecordOrUpload ? 204 : 52,
            height: 34,
          }}
          sx={{
            py: 0.5,
            px: 3,
            maxWidth: 180,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <VolumeOffOutlined role="presentation" sx={{ pr: 0.5 }} />
            <Typography
              sx={{ fontSize: "12px", fontStyle: "italic", fontWeight: 500 }}
            >
              Hands-free is off
            </Typography>
          </Box>
        </ContextualFooter>
      ) : null}
      {canRecordOrUpload && !userReadOnlyMode ? (
        <>
          <SessionRecorderFooter
            workspaceId={currentWorkspaceId}
            feedId={feedId}
          />
        </>
      ) : (
        <ContextualFooter sx={{ height: isSilentChannel ? 64 : 34 }}>
          {userReadOnlyMode
            ? "You are in Read Only mode"
            : "You are a listener in this channel"}
          {isSilentChannel ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <VolumeOffOutlined role="presentation" sx={{ pr: 0.5 }} />
              <Typography
                sx={{ fontSize: "12px", fontStyle: "italic", fontWeight: 500 }}
              >
                Hands-free is off
              </Typography>
            </Box>
          ) : null}
        </ContextualFooter>
      )}
    </>
  );
}

export default observe(FeedC);
