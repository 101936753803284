/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { GeotabLoginRequest, GeotabLoginResponse, TokenExchangeRequest, TokenExchangeResponse } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Login<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name Auth0TokenexchangeCreate
   * @summary auth0TokenExchange
   * @request POST:/login/auth0/tokenexchange
   */
  auth0TokenexchangeCreate = (body: TokenExchangeRequest, params: RequestParams = {}) =>
    this.request<TokenExchangeResponse, any>({
      path: `/login/auth0/tokenexchange`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name Auth0CallbackList
   * @summary callback
   * @request GET:/login/auth0/callback
   */
  auth0CallbackList = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/login/auth0/callback`,
      method: "GET",
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name GeotabCreate
   * @summary geotabLogin
   * @request POST:/login/geotab
   */
  geotabCreate = (body: GeotabLoginRequest, params: RequestParams = {}) =>
    this.request<GeotabLoginResponse, any>({
      path: `/login/geotab`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name LoginList
   * @summary login
   * @request GET:/login
   */
  loginList = (
    query?: {
      /** URL to send the user after successful login */
      returnTo?: string;
      /** alternative authentication methods such as password */
      authMethod?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/login`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name Auth0List
   * @summary loginAuth0
   * @request GET:/login/auth0
   */
  auth0List = (
    query?: {
      /** URL to send the user after successful login */
      returnTo?: string;
      /** email address or phone number of unauthenticated user */
      identifier?: string;
      /** name of social or sso idp */
      connection?: string;
      /** alternative authentication methods such as password */
      authMethod?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/login/auth0`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @name TokenList
   * @summary tokenLogin
   * @request GET:/login/token
   */
  tokenList = (
    query: {
      token: string;
      returnTo?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/login/token`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      ...params,
    });
}
